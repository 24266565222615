.card {
  background-color: var(--gray-0);
  border: 1px solid var(--border-secondary);
  border-radius: 12px;
  box-shadow: unset;

  padding: 16px;

  :global(.p-card-body) {
    padding: 0;

    :global(.p-card-content) {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;

      padding: 0;

      ul {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        padding-left: 20px;

        color: var(--text-primary);
        font: 400 14px/20px 'Inter', sans-serif;
      }
    }
  }
}
