.formEl {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;

  @media screen and (min-width: 576px) {
    gap: 4px;
  }

  &.error input {
    border-color: var(--brand-accent-500);
  }

  > * {
    width: 100%;
  }

  label {
    color: var(--text-secondary);
    font: 500 12px/18px "Inter", sans-serif;
    width: unset;

    @media screen and (min-width: 576px) {
      font-size: 14px;
      line-height: 20px;
    }

    > span {
      color: var(--text-brand-tertiary);
      margin-left: 2px;
    }
  }

  input:not([type='checkbox']),
  textarea,
  div[data-pc-name='dropdown'] {
    background-color: var(--gray-0);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);

    padding: 10px 14px;
    width: 100%;

    color: var(--text-quarternary);
    font: 400 16px/24px "Inter", sans-serif;
  }

  textarea {
    min-height: 100px;
  }

  div[data-pc-name='dropdown'] {
    padding: 0;

    > span[data-pc-section='input'] {
      padding: 10px 0 10px 14px;
    }

    div[data-pc-section='trigger'] {
      padding: 10px 14px;
      width: auto;
    }
  }

  .calendar {
    button {
      border: none;
      background-color: transparent;
      box-shadow: unset;

      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      padding: 0 6px 0 14px;
      height: 100%;

      position: absolute;

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: var(--button-secondary-fg);
        }
      }
    }

    input {
      color: var(--text-quarternary);
      font: 600 14px/20px "Inter", sans-serif;

      padding-left: 36px;
    }
  }
}

div[data-pc-name='password'] {
  > div {
    width: 100%;
  }
}
