.formEl {
  background-color: var(--gray-0);
  border: 1px solid var(--border-primary);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

  color: var(--text-quarternary);
  font: 400 16px/24px "Inter", sans-serif;

  overflow: hidden;
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0;

  &::after {
    content: '/';
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translateY(-50%);

    color: var(--border-primary);
    font: 400 16px/24px "Inter", sans-serif;
  }

  > div > div {
    background-color: unset!important;
    border: unset!important;
    border-radius: 0!important;
    box-shadow: unset!important;

    > div:nth-of-type(3) {
      display: none;
    }
    
    > span {
      padding: 10px 0 10px 14px;
    }
  }
}
