.logo {
  max-width: 166px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  > div:last-of-type {
    display: none;

    @media screen and (min-width: 400px) {
      display: block;
    }
  }
}