.menuSettings {
  background-color: var(--gray-0);

  width: 100%;

  position: fixed;
  inset: auto 0 0 0;

  @media screen and (min-width: 768px) {
    border-radius: 8px;
    border: 1px solid var(--border-secondary);
    box-shadow: 0px 12px 16px -4px rgba(10, 13, 18, 0.08),
      0px 4px 6px -2px rgba(10, 13, 18, 0.03),
      0px 2px 2px -1px rgba(10, 13, 18, 0.04);

    position: absolute;
    inset: 48px 0 auto auto;
    width: 250px;
  }

  .header {
    border-bottom: 1px solid var(--border-secondary);
    padding: 12px 16px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    .avatar {
      background-color: var(--gray-4);
      border-radius: 50%;
      border: 0.75px solid rgba(0, 0, 0, 0.08);

      color: var(--blue-fg-quarternary);
      font: 600 16px/24px "Inter", sans-serif;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      flex: 0 0 40px;

      height: 40px;
      width: 40px;
    }

    .user {
      @media screen and (min-width: 576px) {
        width: 164px;
      }

      p {
        width: inherit;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-of-type {
          color: var(--text-secondary);
          font: 600 14px/20px "Inter", sans-serif;
        }
        
        &:last-of-type {
          color: var(--text-tertiary);
          font: 400 14px/20px "Inter", sans-serif;
        }
      }   
    }
  }

  .content {
    border-bottom: 1px solid var(--border-secondary);
    padding: 4px 6px;

    > * {
      width: 100%;
    }
  }

  .footer {
    padding: 4px 6px;

    .logout {
      border-radius: 6px;
      cursor: pointer;
      padding: 8px 10px;

      color: var(--text-secondary);
      font: 600 14px/20px "Inter", sans-serif;
      
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      transition: background-color 0.2s;

      &:hover {
        background-color: var(--bg-primary-hover);
      }
    }
  }
}
