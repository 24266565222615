.stalls {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0;

  position: fixed;
  inset: 72px 0 0;

  overflow-y: scroll;
  width: 100%;

  &:global(.active) {
    width: calc(100% - 566px);
  }

  :global(#droppable) {

    :global(#stalls) & {

      @media screen and (min-width: 576px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        transition: grid-template-columns 0.3s ease-in-out;
        
        height: auto;
        width: 100%;
      }
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (min-width: 1100px) {
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
      }

      :global(.active) & {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    
    > div {
      width: inherit;
      width: 100%;

      @media screen and (min-width: 576px) {
        width: 23%;

        :global(#stalls) & {
          width: 100%;
        }
      }

      &:last-of-type {
        margin-right: auto;
      }

      :global(#stalls) & {
        max-width: unset;
      }
    }
  }

  .tray > div[data-component='global-banner'] > div&:first-of-type {
    border-radius: 12px;
    box-shadow: 0px 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03), 0px 2px 2px -1px rgba(10, 13, 18, 0.04);

    flex-flow: column nowrap;
    text-align: center;

    margin-bottom: 8px;
    padding: 16px 48px;
  }
}

.stallCard {
  background-color: var(--gray-0);
  border-radius: 8px;
  border: 2px solid var(--gray-50);
  box-shadow: 0px 4px 8px 0px rgba(25, 53, 66, 0.08);

  padding: 16px;
  /* min-height: 290px; */
  max-width: 393px;
  width: 100%;

  .holder {
    position: relative;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    height: 100%;

    .date {
      background-color: var(--gray-50);
      border-radius: 3px;
      padding: 2px 4px;

      color: var(--text-quarternary);
      font: 600 10px/14px 'Inter', sans-serif;
    }
  }

  .cardHeader {
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    h3 {
      color: var(--text-primary);
      font: 700 12px/16px 'Inter', sans-serif;

      @media screen and (min-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    span {
      color: var(--text-primary);
      font: 400 12px/16px 'Inter', sans-serif;

      @media screen and (min-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .imgBlock {
    border-radius: 4px;
    background-color: var(--gray-50);

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    min-height: 150px;
    width: 100%;

    @media screen and (min-width: 576px) {
      flex: 1;
    }

    :global(#home) & {
      flex: unset;
      height: 165px;
    }

    img {
      height: auto;
      width: 148%;

      :global(#home) &,
      :global(#stalls) & {
        width: 100%;
      }
    }

    &.plc svg {
      height: 50px;
      width: 50px;
    }
  }
}

.tray {
  background-color: var(--gray-0);
  border-radius: 12px;
  border: 1px solid var(--border-color);

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  flex: 1;

  padding: 16px;
  height: 100%;
  overflow-y: scroll;
}

.stallView {
  background-color: var(--gray-0);
  border: 1px solid var(--border-color);
  border-radius: 12px;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex: 1;

  padding: 16px;
  height: 100%;

  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    flex: 1;

    position: relative;
    padding-bottom: 16px;
    overflow-y: scroll;
    width: 100%;

    > * {
      width: 100%;
    }

    .head {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: space-between;
      gap: 8px;

      color: var(--gray-text-700);
      font: 400 16px/20px 'Inter', sans-serif;
      
      .horses {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
      }
    }

    .videoBlock {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      position: relative;

      .expand {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        height: 24px;
        width: 24px;

        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        position: absolute;
        inset: 7px 7px auto auto;
        z-index: 1;

        svg path {
          fill: var(--gray-0);
        }
      }

      .cameraBlock {
        width: 100%;
      }

      .formEl {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2px;

        width: 100%;

        label {
          color: var(--text-primary);
          font: 700 12px/14px 'Inter', sans-serif;

          @media screen and (min-width: 600px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        > div {
          width: 100%;
        }
      }
    }
  }

  .actions {
    border-top: 1px solid var(--border-secondary);
    padding-top: 8px;
    width: 100%;

    button {
      flex: 1;
    }
  }
}

:global(.p-sidebar-mask) :global(#globalBanner) > div:first-of-type {
  border-radius: 12px;
  box-shadow: 0px 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03), 0px 2px 2px -1px rgba(10, 13, 18, 0.04);
  flex-flow: column nowrap;
  padding: 16px 48px;
}
