.padding {
  padding: 24px 32px;
}

.calendar {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  padding: 24px 32px;

  position: relative;
}

.calendarBlock {
  border-radius: 12px;
  border: 1px solid var(--border-secondary);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */

  position: relative;

  /* padding: 32px; */
  max-width: 1280px;
  overflow: hidden;
  width: 100%;

  .calendarEl {
    width: 100%;

    > div:first-of-type {
      margin-right: 156px;
    }

    /* > button {
      order: 2;
    } */
  }

  .monthBlock {
    position: absolute;
    top: 20px;
    left: 24px;

    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    
    .today {
      border: 1px solid var(--border-secondary);
      border-radius: 8px;
      background-color: var(--gray-0);

      overflow: hidden;
      width: 64px;

      display: inline-flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;

      .mon {
        background-color: var(--bg-secondary);
        padding: 4px 0;
        width: 100%;

        color: var(--text-quarternary);
        font: 600 12px/18px 'Inter', sans-serif;
        text-align: center;
        text-transform: uppercase;
      }

      .day {
        padding: 2px 0;
        text-align: center;

        color: var(--text-brand-secondary);
        font: 700 18px/28px 'Inter', sans-serif;
      }
    }

    .monthInfo {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2px;

      .mon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        color: var(--text-primary);
        font: 600 18px/28px 'Inter', sans-serif;

        .week {
          background-color: var(--gray-0);
          border: 1px solid var(--button-secondary-border);
          border-radius: 6px;
          box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);

          color: var(--text-secondary);
          font: 500 12px/18px 'Inter', sans-serif;

          padding: 2px 6px;
        }
      }

      .monthDays {
        color: var(--text-tertiary);
        font: 400 14px/20px 'Inter', sans-serif;
      }
    }
  }

  > button {
    border-width: 1px;
    position: absolute;
    top: 27px;
    right: 24px;
  }
}

.timeslots {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  flex: 1.5;
  position: relative;

  .sep {
    background-color: var(--text-placeholder-subtle);
    height: 1px;
    width: 4px;

    content: '';
    position: absolute;
    inset: auto 0 20px 50%;
    transform: translateX(-50%);
  }

  > div:last-of-type > label {
    visibility: hidden;
  }

  input {
    padding-left: 14px!important;
  }
}

.eventDetails {
  padding: 20px 40px;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  .details {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    > * {
      width: 100%;
    }

    .header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .title {
        color: var(--text-primary);
        font: 600 16px/24px 'Inter', sans-serif;
      }

      .actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 2px;
      }
    }

    .info {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 6px;

      > div {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;

        color: var(--text-tertiary);
        font: 400 14px/20px 'Inter', sans-serif;

        svg {
          position: relative;
          top: 2px;

          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .avatars {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px 0;

    .avatar {
      background-color: var(--border-avatar);
      border: 2px solid var(--gray-0);
      border-radius: 50%;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      color: var(--fg-quarternary-500);
      font: 600 14px/20px 'Inter', sans-serif;

      margin-left: -2px;

      position: relative;
      height: 32px;
      width: 32px;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.08);
      }

      &:not(:first-of-type) {
        margin-left: -8px;
      }
    }

    .more {
      border: 1px dashed var(--border-primary);
      border-radius: 50%;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      margin-left: 8px;
      height: 30px;
      width: 30px;
    }

    .numbers {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      width: 100%;

      .num {
        color: var(--text-tertiary);
        font: 400 14px/20px 'Inter', sans-serif;
        
        position: relative;

        &::before {
          content: '';
          background-color: var(--fg-senary-300);
          width: 1px;

          position: absolute;
          inset: 4px -9px 4px auto;
        }

        &:first-of-type {
          color: var(--text-primary);
          font-weight: 600;
        }

        &:last-of-type::before {
          display: none;
        }
      }
    }
  }

  .supporting {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    color: var(--text-tertiary);
    font: 400 14px/20px 'Inter', sans-serif;

    h2 {
      color: var(--text-primary);
      font: 600 14px/20px 'Inter', sans-serif;
    }
  }
}