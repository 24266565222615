.error-boundary-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  
    .error-content {
      text-align: center;
      color: #000;
      font-size: 2rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      margin: 0 2rem;
      h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      a {
        text-decoration: underline;
      }
    }
}  