.header {
  background-color: var(--gray-0);
  border-bottom: 1px solid var(--border-secondary);

  height: 72px;
  padding: 0 16px;
  width: 100%;

  position: fixed;
  inset: 0 0 0 0;
  z-index: 100;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 576px) {
    justify-content: flex-start;
    padding: 0 3%;
  }
  @media screen and (min-width: 768px) {
    gap: 24px;
  }
  @media screen and (min-width: 992px) {
    gap: 32px;
  }

  > div:last-of-type {
    @media screen and (min-width: 576px) {
      margin-left: auto;
    }
  }
}
