.cardSection {
  padding: 16px;  
  transition: margin-right 0.25s ease-in-out;

  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;

  @media screen and (min-width: 500px) {
    padding-right: 3%;
    padding-left: 3%;
  }
  @media screen and (min-width: 1100px) {
    gap: 24px;

    padding-top: 24px;
    padding-bottom: 24px;
  }
}
