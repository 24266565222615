@use "sass:map";

// file: client/src/scss/utils/_mixins.scss
@import "./variables";
@import "./functions";

/// Mixin for abs-center
/// @param {number} $z [1]

@mixin abs-center($z: 1) {
  @include absolute($top: 50%, $left: 50%, $z: $z);
  @include css3-prefix(transform, translate(-50%, -50%));
}

/// Mixin for arrow
/// @param {string} $dir    - [down] - up, right, down, left
/// @param {string} $width  - [0.2rem]
/// @param {string} $color  - [#000000]

@mixin arrow($dir: down, $size: 0.2rem, $style: solid, $color: #000000) {
  height: 0;
  width: 0;

  @if ($dir == top) {
    border: {
      left: $size $style transparent;
      right: $size $style transparent;
      bottom: $size $style $color;
    }
  } @else if ($dir == right) {
    border: {
      top: $size $style transparent;
      bottom: $size $style transparent;
      left: $size $style $color;
    }
  } @else if ($dir == down) {
    border: {
      left: $size $style transparent;
      right: $size $style transparent;
      top: $size $style $color;
    }
  } @else if ($dir == left) {
    border: {
      top: $size $style transparent;
      bottom: $size $style transparent;
      right: $size $style $color;
    }
  } @else {
    border: {
      left: $size $style transparent;
      right: $size $style transparent;
      bottom: $size $style $color;
    }
  }
}

/// Mixin backdrop-filter
/// @param {string} $type   - [blur] - blur, brightness, contrast, drop-shadow, grayscale, hue-rotate, invert, opacity, sepia, saturate
/// @param {string} $num    - [0]

@mixin backdrop-filter($type: blur, $num: 0) {
  -webkit-backdrop-filter: setupFilter($type, $num);
  backdrop-filter: setupFilter($type, $num);
}

/// Mixin border
/// @param {string} $width  - [0.1rem]
/// @param {string} $style  - [solid] - none, hidden, dotted, dashed, solid, double, groove, ridge, inset, outset
/// @param {string} $color  - [$black]
/// @param {string} $el     - [all] - all, top, right, bottom, left, y, x

@mixin border($el: null, $width: 0.1rem, $style: solid, $color: transparent) {
  @if $el == null {
    border: {
      style: $style;
      color: $color;
      width: $width;
    }
  } @else if $el == "y" {
    border: {
      style: $style;
      color: $color;
      top-width: $width;
      bottom-width: $width;
      right-width: 0;
      left-width: 0;
    }
  } @else if $el == "x" {
    border: {
      style: $style;
      color: $color;
      right-width: $width;
      left-width: $width;
      top-width: 0;
      bottom-width: 0;
    }
  } @else {
    border: {
      style: $style;
      color: $color;
      width: 0;
      #{$el}-width: $width;
    }
  }
}

/// Alias mixin for border-<direction> - Serves as an alias for `border(<direction>, ...)`
/// @param {argslist}   $args
/// @require {mixin}    border

@mixin bd-top($args...) {
  @include border(top, $args...);
}
@mixin bd-right($args...) {
  @include border(right, $args...);
}
@mixin bd-bottom($args...) {
  @include border(bottom, $args...);
}
@mixin bd-left($args...) {
  @include border(left, $args...);
}
@mixin bd-y($args...) {
  @include border(y, $args...);
}
@mixin bd-x($args...) {
  @include border(x, $args...);
}

/// Mixin border-rad
/// @param {string} $radius - [0]

@mixin border-rad($radius: 0) {
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

/// Mixin box-shadow
/// @param {string}     $horiz  - [0] - offset x
/// @param {string}     $vert   - [0] - offset y
/// @param {string}     $blur   - [0] - blur radius
/// @param {string}     $spread - [0] - spread
/// @param {string}     $color  - [transparent]
/// @param {boolean}    $inset  - [false]

@mixin box-shadow(
  $horiz: 0,
  $vert: 0,
  $blur: 0,
  $spread: 0,
  $color: transparent,
  $inset: false
) {
  @if $inset {
    -webkit-box-shadow: inset $horiz $vert $blur $spread $color;
    -moz-box-shadow: inset $horiz $vert $blur $spread $color;
    box-shadow: inset $horiz $vert $blur $spread $color;
  } @else {
    -webkit-box-shadow: $horiz $vert $blur $spread $color;
    -moz-box-shadow: $horiz $vert $blur $spread $color;
    box-shadow: $horiz $vert $blur $spread $color;
  }
}

/// Mixin box-sizing
/// @param {string} $box-model - [border-box] - content-box|border-box|initial|inherit

@mixin box-sizing($box-modal: border-box) {
  -webkit-box-sizing: $box-modal;
  -moz-box-sizing: $box-modal;
  box-sizing: $box-modal;
}

/// Mixin css3-prefix - Add vendor prefixes to css property
/// @param {string} $prop
/// @param {string} $val

@mixin css3-prefix($prop, $val) {
  @if $prop == null {
    @error "Requires a valid CSS property. | mixin: css3-prefix";
  }
  @if $val == null {
    @error "Requires a value for the valid CSS property. | mixin: css3-prefix";
  }

  -webkit-#{$prop}: #{$val};
  -moz-#{$prop}: #{$val};
  -ms-#{$prop}: #{$val};
  -o-#{$prop}: #{$val};
  #{$prop}: #{$val};
}

/// Mixin flexbox
/// @param {string} $ai - [center] - center, start, end, flex-start, flex-end, self-start, self-end
/// @param {string} $fd - [row] - row, row-reverse, column, column-reverse
/// @param {string} $jc - [start] - center, start, end, flex-start, flex-end, left, right
/// @param {string} $fw - [wrap] - nowrap, wrap, wrap-reverse

@mixin flexbox($fd: row, $ai: center, $jc: flex-start, $fw: null, $gap: null) {
  align-items: $ai;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: {
    direction: $fd;
    wrap: $fw;
  }
  gap: $gap;
  justify-content: $jc;
}

/// Mixin font
/// @param {string} $family - [$inter]
/// @param {string} $size   - [14px] - px only
/// @param {string} $weight - [400] - normal, bold, lighter, bolder, 100, 200, 300, 400, 500, 600, 700, 800, 900

@mixin font($family: map-get($map: $fonts, $key: 'inter'), $size: 14px, $weight: 400) {
  font: {
    family: $family;
    size: $size;
    size: calculateRem($size);
    weight: $weight;
  }
  line-height: calculateLineHeight($size);
}

/// Mixin font-size
/// @param {string} $size [14px] - px only

@mixin font-size($size: 14px) {
  font: {
    size: $size;
    size: calculateRem($size);
  }
}

/// Mixin getColor
/// @param {string} $type - ['color'] - background, border
/// @param {string} $color = ['transparent']

@mixin getColor($type: "color", $color: "transparent") {
  @if $type == "color" {
    // color: map-get($map: $colors, $key: $color);
    color: $color;
  } @else {
    #{$type}-color: $color;
  }
}

/// Mixin keyframes
/// @param {string} $name
/// @param {args}   @content    - style rules

@mixin keyframes($name) {
  @if $name == null {
    @error "Requires a name. | mixin: keyframes";
  }

  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($str) {
  @if $str == null {
    @error "Requires a string. | mixin: animation";
  }

  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

/// Mixin mar
/// @param {string} $top    - [null]
/// @param {string} $right  - [null]
/// @param {string} $bottom - [null]
/// @param {string} $left   - [null]

@mixin mar($top: null, $right: null, $bottom: null, $left: null) {
  margin: {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}
@mixin marx($num: null) {
  margin: {
    right: $num;
    left: $num;
  }
}
@mixin mary($num: null) {
  margin: {
    top: $num;
    bottom: $num;
  }
}
@mixin marxy($num: 0) {
  margin: {
    top: $num;
    right: $num;
    bottom: $num;
    left: $num;
  }
}

/// Mixin media - Setting media queries; allows dev to set breakpoint
/// @param {string} $size       - breakpoint for media query
/// @param {args}   @content    - style rules

@mixin media($size) {
  @if $size == null {
    @error "Requires a width. | mixin: media";
  }

  @media screen and (min-width: $size) {
    @content;
  }
}

/// Mixin mediaH - Setting media queries (height); allows dev to set breakpoint
/// @param {string} $size       - breakpoint for media query
/// @param {args}   @content    - style rules

@mixin mediaH($size) {
  @if $size == null {
    @error "Requires a height. | mixin: mediaH";
  }

  @media screen and (min-height: $size) {
    @content;
  }
}

/// Mixin opacity - Creates opacity and fallbacks for IE
/// @param {number} $opacity - [1]

@mixin opacity($opacity: 1) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" $opacity * 100
    ")";
  filter: alpha(opacity=$opacity-ie);
  zoom: 1;
}

/// Mixin placeholder - Creates support for browsers
/// @param {args} @content - style rules

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

/// Mixin pad
/// @param {string} $top    - [null]
/// @param {string} $right  - [null]
/// @param {string} $bottom - [null]
/// @param {string} $left   - [null]

@mixin pad($top: null, $right: null, $bottom: null, $left: null) {
  padding: {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}
@mixin padx($num: null) {
  padding: {
    right: $num;
    left: $num;
  }
}
@mixin pady($num: null) {
  padding: {
    top: $num;
    bottom: $num;
  }
}
@mixin padxy($num: 0) {
  padding: {
    top: $num;
    right: $num;
    bottom: $num;
    left: $num;
  }
}

/// Mixin position
/// @param {string} $pos    - [relative] - static, relative, absolute, fixed, sticky
/// @param {string} $top    - [null]
/// @param {string} $right  - [null]
/// @param {string} $bottom - [null]
/// @param {string} $left   - [null]
/// @param {number} $z      - [0]

@mixin position(
  $pos: relative,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $z: 0
) {
  position: $pos;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z;
}

/// Alias mixin for absolute positioning - Serves as an alias for `position(<position>, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position

@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}
@mixin static() {
  @include position(static);
}
@mixin sticky($args...) {
  @include position(sticky, $args...);
}

/// Mixin pushAuto - Center block element w/out top/btm margins

@mixin pushAuto {
  margin: {
    left: auto;
    right: auto;
  }
}

/// Mixin pseudo - Used with ::before / ::after
/// @param {string} $display    - [block] - block, inline, inline-block, flex, inline-flex, grid, inline-grid, flow-root, none, contents, table, table-row, list-item
/// @param {string} $pos        - [absolute] - static, relative, absolute, fixed, sticky
/// @param {string} $content    - ['']

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

/// Mixin retina
/// @param {string} $img
/// @param {string} $w
/// @param {string} $h

@mixin retina($image, $width, $height) {
  @if $image == null {
    @error "Requires an image url. | mixin: retina";
  }
  @if $width == null {
    @error "Requires a width for the image. | mixin: retina";
  }
  @if $height == null {
    @error "Requires a height for the image. | mixin: retina";
  }

  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

/// Mixin for square
/// @param {string} $num - [null]

@mixin square($num: null) {
  @if $num == null {
    @error "Requires a number. | mixin: square";
  }

  height: $num;
  width: $num;
}

/// Mixin transform
/// @param {string} $property - [null]

@mixin transform($property: null) {
  @if $property == null {
    @error "Requires a valid CSS transform function value";
  }

  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

/// Mixin for truncate
/// @param {string} $overflow - [ellipsis] - clip, ellipsis, initial, inherit, [string]

@mixin truncate($boundary: null) {
  @if $boundary == null {
    @error "Requires a max-width for a boundary. | mixin: truncate";
  }

  max-width: $boundary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/// Mixin for unstyled-list

@mixin unstyled-list() {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

/// Mixin vert-center - Vertically center element

@mixin vert-center {
  @include relative($top: 50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
