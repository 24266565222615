@use 'sass:map';

.rectangleDiv {
    align-self: stretch;
    position: relative;
    background-color: var(--admin-primary-blue);
    height: 249px;
}

.frameChild1 {
    align-self: stretch;
    position: relative;
    background-color: var(--secondary);
    height: 10px;
}

.rectangleParent {
    width: 100%;
    margin: 0 !important;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.vectorIcon {
    width: 124px;
    position: relative;
    height: 122px;
}

.showmanship {
    position: relative;
    line-height: 100%;
}

.vectorParent {
    height: 198.1px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    z-index: 1;
}

.vectorParent.animate {
    animation: 1s ease-in-out 0s 1 normal forwards scale-up;
}

@keyframes scale-up {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

.midFooter {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.showmanshipAllRights {
    text-decoration: none;
    height: 18px;
    width: 364px;
    position: relative;
    line-height: 100%;
    color: inherit;
    display: inline-block;
    flex-shrink: 0;
}

.showmanshipAllRightsReservWrapper {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.logIn {
    text-decoration: none;
    height: 18px;
    width: 153px;
    position: relative;
    line-height: 100%;
    color: inherit;
    display: inline-block;
    flex-shrink: 0;
}

.logInWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}
.logInWrapper a {
    color: var(--admin-primary-blue);
}
.frameContainer {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bottomFooter {
    align-self: stretch;
    height: 124px;
    background-color: var(--white);
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 41px var(--padding-21xl);
    box-sizing: border-box;
    font-size: var(--font-size-lg);
    color: var(--admin-primary-blue);
    font-family: var(--font-inter);
}

.footerSection {
    width: 100%;
    height: 383px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--font-size-29xl);
    color: var(--white);
    font-family: var(--font-korolev);
}

@media screen and (max-width: 420px) {
    .showmanshipAllRights {
        font-size: var(--font-size-sm);
        text-align: center;
    }

    .showmanshipAllRightsReservWrapper {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: var(--padding-xl);
        box-sizing: border-box;
        flex: unset;
        align-self: stretch;
    }

    .logIn {
        font-size: var(--font-size-sm);
        text-align: center;
    }

    .logInWrapper {
        align-items: center;
        justify-content: center;
        flex: unset;
        align-self: stretch;
    }

    .frameContainer {
        flex-direction: column;
    }

    .bottomFooter {
        flex-direction: row;
    }
}