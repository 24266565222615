.trainers {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0;

  width: 100%;
}

.cardSection {
  padding: 16px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  transition: margin-right 0.25s ease-in-out;

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);

    padding-right: 3%;
    padding-left: 3%;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1100px) {
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 24px;

    padding-top: 24px;
    padding-bottom: 24px;
  }

  :global(.p-card) {
    display: flex;

    :global(.p-card-body) {
      display: flex;
    }

    :global(.p-card-content) {
      justify-content: space-between;
      flex: 1;
    }
  }

  .head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    img {
      border: 1px solid #00000007;
      border-radius: 50%;

      height: 48px;
      width: 48px;
    }

    > div {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;

      h3 {
        color: var(--text-primary);
        font: 600 18px/28px 'Inter', sans-serif;
      }

      p {
        color: var(--text-tertiary);
        font: 400 14px/20px 'Inter', sans-serif;
      }
    }
  }

  .body {
    color: var(--text-secondary);
    font: 400 14px/20px 'Inter', sans-serif;
    margin-bottom: auto;
  }

  .foot {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    button {
      /* flex: 1; */
    }
  }
}

.monthBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  padding: 0 16px;
  
  .today {
    border: 1px solid var(--border-secondary);
    border-radius: 8px;
    background-color: var(--gray-0);

    overflow: hidden;
    width: 64px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    .mon {
      background-color: var(--bg-secondary);
      padding: 4px 0;
      width: 100%;

      color: var(--text-quarternary);
      font: 600 12px/18px 'Inter', sans-serif;
      text-align: center;
      text-transform: uppercase;
    }

    .day {
      padding: 2px 0;

      color: var(--text-brand-secondary);
      font: 700 18px/28px 'Inter', sans-serif;
    }
  }

  .monthInfo {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;

    .mon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      color: var(--text-primary);
      font: 600 18px/28px 'Inter', sans-serif;

      .week {
        background-color: var(--gray-0);
        border: 1px solid var(--button-secondary-border);
        border-radius: 6px;
        box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);

        color: var(--text-secondary);
        font: 500 12px/18px 'Inter', sans-serif;

        padding: 2px 6px;
      }
    }

    .monthDays {
      color: var(--text-tertiary);
      font: 400 14px/20px 'Inter', sans-serif;
    }
  }
}

.footer {
  padding: 16px 24px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  position: relative;

  &::before {
    background-color: var(--border-secondary);
    height: 1px;

    content: '';

    position: absolute;
    inset: 0 16px;
  }

  button:only-of-type {
    margin-left: auto;
  }
}

.methodsForm > div {
  flex-flow: column nowrap!important;

  .savedMethods {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    .method {
      background-color: var(--gray-0);
      border: 1px solid var(--border-secondary);
      border-radius: 12px;

      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: 4px;

      padding: 16px;
      width: 100%;

      .icon {
        border: 1px solid var(--border-secondary);
        border-radius: 4px;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        margin-right: 8px;
        height: 32px;
        width: 46px;
      }

      .info {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        margin-right: auto;

        color: var(--text-secondary);
        font: 500 14px/20px 'Inter', sans-serif;

        .actions {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          button {
            color: var(--text-tertiary);
            font: 600 14px/20px 'Inter', sans-serif;
          }
        }
      }

      div[data-pc-name='checkbox'] {
        input {
          border: 0;
          border-radius: unset;
        }

        div[data-pc-section='box'] {
          border: 1px solid var(--border-primary);
          border-radius: 4px;

          height: 16px;
          width: 16px;

          &[data-p-highlight='true'] {
            background-color: var(--bg-brand-solid);
            border-color: var(--bg-brand-solid);
          }

          svg {
            height: 10px;
            width: 10px;

            path {
              fill: var(--gray-0);
              stroke: var(--gray-0);
            }
          }
        }
      }
    }

    > button {
      margin-right: 0;
      margin-left: auto;
    }
  }
}
