.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    h1 {
      color: #437266;
      font-size: 5rem;
      margin-bottom: 6rem;
    }
    .logo {
      margin-bottom: 6rem;
      width: 136px;
      height: 93px;
    }
    a {
      font-size: 3rem;
      color: #437266;
      text-decoration: underline;
    }
    a:hover {
      color: #000;
    }
}