.globalBanner {
  position: relative;
  width: 100%;

  .close {
    position: absolute;
    inset: 8px 8px auto auto;

    cursor: pointer;

    @media screen and (min-width: 576px) {
      inset: 10px 10px auto auto;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.banner {
  border-bottom: 1px solid var(--text-brand-tertiary);
  background: var(--text-brand-secondary);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 24px 16px;

  color: #F6F6F6;
  font: 400 12px/20px 'Inter', sans-serif;

  @media screen and (min-width: 576px) {
    flex-flow: row nowrap;
    gap: 6px;
    padding: 8px 36px;
  }
  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  span {
    font-weight: 600;
  }

  &.reminder {
    border-bottom: 1px solid var(--text-brand-tertiary);
    background: var(--text-brand-secondary);
  }

  &.warning {
    background: var(--brand-accent-600);
    border-bottom-color: var(--brand-accent-700);
  }
}
