:root {

  /* Grays */
  --gray-0: #FFFFFF;
  --gray-4-1: #F6F6F6;
  --gray-50: #F6F6F6;
  --gray-4: #F5F5F5;
  
  --gray-text-700: #131415;

  --gray-utility-50: #FAFAFA;
  --gray-utility-200: #E9EAEB;
  --gray-utility-700: #414651;

  /* Greens */
  --green-base: #193542;

  --green-utility-50: #EDFCF2;
  --green-utility-200: #AAF0C4;
  --green-utility-500: #16B364;
  --green-utility-600: #099250;
  --green-utility-700: #087443;

  /* Blues */
  --blue-fg-quarternary: #717680;

  --blue-utility-50: #EFF8FF;
  --blue-utility-200: #B2DDFF;
  --blue-utility-600: #1570EF;
  --blue-utility-700: #175CD3;

  --blue-random-001: #ACCBEE;
  --blue-random-002: #E7F0FD;


  /* Backgrounds */
  --bg-primary: #FFFFFF;
  --bg-primary-hover: #EDFAFA;
  --bg-active: #193542;

  --bg-secondary: #FAFAFA;

  --bg-brand-solid: #386A81;

  --bg-disabled: #F5F5F5;

  /* Borders */
  --border-primary: #D5D7DA;
  --border-secondary: #E9EAEB;

  --border-color: #E6E9EA;

  --border-avatar: #F2F4F7;
  --border-disabled-subtle: #E9EAEB;

  /* Brand colors */
  --brand-accent-500: #D4334A;
  --brand-accent-600: #CF223B;
  --brand-accent-700: #9C1A2D;

  /* Brand Utility */
  --brand-utility-50: #E1F2FA;
  --brand-utility-200: #ADD0E0;
  --brand-utility-600: #2D576B;
  --brand-utility-700: #234657;

  /* Buttons */
  --button-primary-bg: #152E39;
  --button-primary-bg-hover: #07161D;

  --button-secondary-fg: #414651;
  --button-secondary-fg-hover: #252B37;
  --button-secondary-bg: #FFFFFF;
  --button-secondary-bg-hover: #F0F9FF;
  --button-secondary-border: #D5D7DA;
  --button-secondary-active: #69A4BF;

  --button-tertiary-fg: #535862;
  --button-tertiary-fg-hover: #FAFAFA;

  --button-primary-error-bg: #D92D20;
  --button-primary-error-bg-hover: #B42318;

  /* Foreground */
  --fg-senary-300: #D0D5DD;
  --fg-quinary-400: #A4A7AE;
  --fg-quarternary-500: #667085;

  /* Text */
  --text-primary: #181D27;
  --text-primary-700: #07161D;
  
  --text-secondary: #414651;
  --text-brand-secondary: #234657;

  --text-tertiary: #535862;
  --text-brand-tertiary: #2D576B;
  
  --text-quarternary: #717680;

  --text-placeholder-subtle: #D0D5DD;

  /* Yellows */
  --yellow-utility-50: #FEFBE8;
  --yellow-utility-200: #FEEE95;
  --yellow-utility-600: #CA8504;
  --yellow-utility-700: #A15C07;
}
