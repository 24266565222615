.weatherbox {
  border-radius: 12px;
  background: linear-gradient(180deg, var(--blue-random-001) 0%, var(--blue-random-002) 63.28%);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 8px;
  width: 100%;

  @media screen and (min-width: 768px) {
    gap: 16px;
    padding: 16px;
  }

  > * {
    width: 100%;
  }
}

.maindata {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  gap: 16px;

  @media screen and (min-width: 650px) {
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) {
    justify-content: space-around;
  }
  @media screen and (min-width: 1100px) {
    flex-flow: row nowrap;
  }

  .city {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding: 2px 8px;
    border-radius: 32px;
    border: 1px solid var(--text-primary);
    
    color: var(--text-primary);
    font: 400 12px/18px 'Inter', sans-serif;

    order: 0;

    @media screen and (min-width: 650px) {
      font-size: 16px;
      line-height: 24px;
      order: unset;
    }
    @media screen and (min-width: 768px) {
      font-size: 12px;
      line-height: 18px;
      order: 0;
    }
    @media screen and (min-width: 1100px) {
      order: unset;
    }
  }

  .time {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;

    color: var(--text-primary);
    font: 400 16px/24px 'Inter', sans-serif;

    order: 2;
    width: 100%;

    @media screen and (min-width: 650px) {
      order: unset;
      width: unset;
    }
    @media screen and (min-width: 768px) {
      order: 2;
      width: 100%;
    }
    @media screen and (min-width: 1100px) {
      order: unset;
    }

    > div {
      cursor: pointer;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .weather {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: 4px;

    @media screen and (min-width: 650px) {
      flex: unset;
    }
    @media screen and (min-width: 768px) {
      flex: 1;
    }

    h2 {
      color: var(--text-primary);
      font: 600 12px/18px 'Inter', sans-serif;

      @media screen and (min-width: 650px) {
        font-size: 18px;
        line-height: 28px;
      }
      @media screen and (min-width: 768px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.details {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media screen and (min-width: 650px) {
    flex-flow: row wrap;
    gap: 24px;
  }
  @media screen and (min-width: 768px) {
    flex-flow: column nowrap;
    gap: 16px;
  }
  @media screen and (min-width: 1100px) {
    flex-flow: row wrap;
    gap: 24px;
  }

  .left {
    @media screen and (min-width: 650px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: auto;
    }
    @media screen and (min-width: 1100px) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
    @media screen and (min-width: 1200px) {
      width: auto;
    }
  }

  .center {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    gap: 12px;

    position: relative;

    @media screen and (min-width: 650px) {
      flex-flow: row nowrap;
      justify-content: flex-start;
      gap: 24px;

    }
    @media screen and (min-width: 768px) {
      flex-flow: row wrap;
      justify-content: center;
      gap: 12px;
    }
    @media screen and (min-width: 1100px) {
      justify-content: flex-start;
    }
    @media screen and (min-width: 1200px) {
      padding-right: 24px;
      padding-left: 24px;
    }

    &::before,
    &::after {
      background-color: var(--fg-quinary-400);
      display: none;
      
      height: 40px;
      width: 1px;

      content: '';
      position: absolute;
      inset: 50% auto auto auto;
      transform: translateY(-50%);
    }
    &::before {
      left: 0;

      @media screen and (min-width: 650px) {
        display: none;
      }
      @media screen and (min-width: 1200px) {
        display: block;
      }
    }
    &::after {
      right: 0;

      @media screen and (min-width: 768px) {
        display: none;
      }
      @media screen and (min-width: 1100px) {
        display: block;
      }
    }
  }

  .right {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    @media screen and (min-width: 650px) {
      align-items: flex-end;
    }
    @media screen and (min-width: 768px) {
      align-items: center;
    }
    @media screen and (min-width: 1100px) {
      align-items: flex-end;
    }
  }
}

.currenttemp {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    flex-flow: row nowrap;
    gap: 12px;
    max-width: 200px;
  }

  h2,
  h3 {
    color: var(--text-secondary);
  }

  h2 {
    font: 400 28px/40px 'Inter', sans-serif;
    letter-spacing: -0.96px;

    @media screen and (min-width: 650px) {
      font-size: 48px;
      line-height: 60px;
    }
  }

  h3 {
    font: 400 14px/18px 'Inter', sans-serif;

    @media screen and (min-width: 650px) {
      font-size: 18px;
      line-height: 22px;
      
    }
  }
}

.stack {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;

  .right & {
    flex-flow: row nowrap;
  }

  &:last-of-type {
    .right & {
      flex-flow: column nowrap;
      align-items: center;

      @media screen and (min-width: 450px) {
        flex-flow: row nowrap;
        gap: 12px;        
      }
    }
  }

  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    
    color: var(--text-secondary);
    font: 600 14px/20px 'Inter', sans-serif;

    h2 {
      color: var(--text-secondary);
      font: 400 14px/20px 'Inter', sans-serif;
    }
  }
}
