.fc {

  > .fc-view-harness {
    .p-sidebar & {
      /* min-height: 420px; */
      /* overflow-y: hidden; */
      height: auto!important;
    }
  }
  > .fc-view-harness-active {
    .p-sidebar & {
      position: static;
    }

    .fc-view {

      .p-sidebar & {
        position: static;
      }
    }

    .fc-listDay-view {
      .p-sidebar & {
        border: 0;
      }

      .fc-list-table {
        th {
          .p-sidebar & {
            background-color: transparent;
            border: 0;
          }

          .fc-cell-shaded {

            .p-sidebar & {
              background-color: transparent;
              padding: 0;
            }

            > a {
              position: relative;
              text-indent: -9999px;
              
              &::after {
                content: attr(aria-label);
                position: relative;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

  .fc-toolbar.fc-header-toolbar {
    height: 96px;
    margin-bottom: 0;
    margin-right: 24px;

    justify-content: flex-end;
    gap: 12px;

    .p-sidebar & {
      flex-flow: column nowrap;
      align-items: flex-start;
      
      margin-right: 0;
      margin-bottom: 24px;
      padding-right: 16px;
      padding-left: 16px;

      > * {
        width: 100%;
      }

      > div:nth-of-type(2) {
        width: unset;
      } 
    }

    .fc-toolbar-title {
      height: 0;
      width: 0;
      visibility: hidden;
    }
  }

  .fc-button-group {
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset,
      0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset,
      0px 1px 2px 0px rgba(10, 13, 18, 0.05);
      
    .p-sidebar & {
      width: 100%;
    }

    .fc-button {
      border-radius: 8px;
      box-shadow: none!important;

      padding: 10px 14px;
      z-index: 1;
      
      &.fc-button-primary {
        background-color: var(--gray-0);
        border: 1px solid var(--border-primary);
        box-shadow: 0px -2px 0px rgba(10,13,18,0.05) inset!important;

        color: var(--text-secondary);
        font: 600 14px/20px 'Inter', sans-serif;
        text-transform: capitalize;

        opacity: 1;
        outline: none;

        .p-sidebar & {
          padding: 8px 12px;
        }

        &.fc-prev-button,
        &.fc-next-button {
          .p-sidebar & {
            flex: unset;
            width: 44px;
          }
        }

        &.fc-today-button {
          .p-sidebar & {
            flex: 1;
          }
        }

        &:hover {
          background-color: var(--bg-primary-hover);
        }

        &:disabled {
          background-color: var(--gray-0);
          color: rgba(0, 0, 0, 0.25);
        }

        &.fc-button-active {
          background-color: var(--bg-primary-hover);
          position: relative;
          z-index: 0;

          &::after {
            box-shadow: 0px 0px 0px 2px var(--gray-0), 0px 0px 0px 3px var(--button-secondary-active);

            content: '';
            position: absolute;
            inset: -2px;
            z-index: -1;
          }
        }
      }

      &:first-of-type::after {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-of-type::after {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

  }
  
  .fc-scrollgrid {
    border-color: var(--border-secondary);
    border-right-color: transparent;
    border-left: 0;

    .p-sidebar & {
      border: 1px solid var(--border-secondary);
    }

    .fc-scrollgrid-section-header th {
      border-color: var(--border-secondary);
      border-right: 0;

      table {
        width: 100%!important;
      }

      .fc-scrollgrid-sync-inner {
        padding: 8px;

        color: var(--text-quarternary);
        font: 500 12px/18px 'Inter', sans-serif;
      }
    }

    .fc-scrollgrid-section td {
        border-color: var(--border-secondary);
        border-right: 0;

        &[role='presentation'] {
          border-bottom: 0;
        }

        .fc-daygrid-day-frame {
          min-height: 155px;

          .p-sidebar & {
            min-height: 0;
          }
        }

        .fc-daygrid-day-events {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;

          .p-sidebar & {
            flex-flow: row wrap;
          }

          > * {
            min-width: 100%;

            .p-sidebar & {
              min-width: unset;
            }
          }

          > div {
            margin-bottom: 4px;

            .p-sidebar & {
              margin-bottom: 0;
            }
          }
        }

        &.fc-day-today {
          background-color: transparent;

          .fc-daygrid-day-number {
            background-color: var(--bg-brand-solid);
            color: var(--gray-0);
          }
        }
    }
  }

  .fc-col-header-cell-cushion {
    padding: 0;
  }

  .fc-daygrid-body {
    width: 100%!important;

    table {
      width: 100%!important;
    }
  }

  .fc-daygrid-day-top {
    flex-direction: row;

    color: var(--text-secondary);
    font: 600 12px/18px 'Inter', sans-serif;
  }

  .fc-daygrid-day-number {
    border-radius: 50%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    margin: 8px;
    height: 24px;
    width: 24px;

    .p-sidebar & {
      margin: 6px 0 0 6px;
      padding: 0;
    }
  }

  .fc-event {
    &.default {
      background-color: var(--blue-utility-50)!important;
      border-color: var(--blue-utility-200)!important;

      color: var(--blue-utility-700)!important;

      .fc-event-main {
        color: var(--blue-utility-700)!important;
      }

      .fc-daygrid-event-dot {
        background-color: var(--blue-utility-500);
        border-color: var(--blue-utility-500);
      }

      .fc-event-time {
        color: var(--blue-utility-600)!important;
      }
    }
    
    &.afterwork {
      background-color: var(--green-utility-50)!important;
      border-color: var(--green-utility-200)!important;

      color: var(--green-utility-700)!important;

      .fc-event-main {
        color: var(--green-utility-700)!important;
      }

      .fc-daygrid-event-dot {
        background-color: var(--green-utility-500);
        border-color: var(--green-utility-500);
      }

      .fc-event-time {
        color: var(--green-utility-600)!important;
      }
    }

    &.pto {
      background-color: var(--brand-utility-50)!important;
      border-color: var(--brand-utility-200)!important;

      color: var(--brand-utility-700)!important;

      .fc-event-main {
        color: var(--brand-utility-700)!important;
      }

      .fc-daygrid-event-dot {
        background-color: var(--brand-utility-500);
        border-color: var(--brand-utility-500);
      }

      .fc-event-time {
        color: var(--brand-utility-600)!important;
      }
    }

    &.meeting {
      background-color: var(--yellow-utility-50)!important;
      border-color: var(--yellow-utility-200)!important;

      color: var(--yellow-utility-700)!important;

      .fc-event-main {
        color: var(--yellow-utility-700)!important;
      }

      .fc-daygrid-event-dot {
        background-color: var(--yellow-utility-500);
        border-color: var(--yellow-utility-500);
      }

      .fc-event-time {
        color: var(--yellow-utility-600)!important;
      }
    }
  }
  
  .fc-h-event {
    background-color: var(--blue-utility-50);
    border: 1px solid var(--blue-utility-200);
    border-radius: 6px;

    font: 600 12px/18px 'Inter', sans-serif;

    padding: 4px 8px;
    margin-bottom: 4px;

    .p-sidebar & {
      height: 8px;
      padding: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.fc-event-start {
      margin-left: 8px;

      .p-sidebar & {
        height: 8px;
        width: 50px;

        margin: 0 0 0 4px;
        padding: 0;
      }

      &.fc-event-end {
        .p-sidebar & {
          height: 8px;
          min-width: unset;
          width: 8px;

          margin: 0 0 0 4px;
          padding: 0;
        }
      }
    }

    &.fc-event-end {
      margin-right: 8px;

      .p-sidebar & {
        height: 8px;
        min-width: 52px;

        margin: 0 4px 0 -2px;
        padding: 0;
      }
    }

    /* .fc-event-main {
      color: var(--blue-utility-700);
    } */
  }

  .fc-event-title {
    .p-sidebar & {
      display: none;
    }
  }

  .fc-daygrid-event:not(.fc-h-event) {
    background-color: var(--blue-utility-50);
    border: 1px solid var(--blue-utility-200);
    border-radius: 6px;

    color: var(--blue-utility-700);
    font: 600 12px/18px 'Inter', sans-serif;

    padding: 4px 8px;
    margin: 0 8px;

    .p-sidebar & {
      height: 8px;
      width: 8px;

      margin: 0 0 0 4px;
      padding: 0;
    }

    .fc-daygrid-event-dot {
      order: 0;

      .p-sidebar & {
        display: none;
      }
    }
    .fc-event-title {
      order: 1;
    }
    .fc-event-time {
      order: 2;
      margin-right: 0;
      margin-left: 3px;

      color: var(--blue-utility-600);
      font-weight: 400;

      .p-sidebar & {
        display: none;
      }
    }
  }

  .fc-scroller {
    &.fc-scroller-liquid-absolute {

      .p-sidebar & {
        overflow-y: hidden!important;
        position: static;
      }
    }
  }

  &#calendarList {
    width: 100%;

    .p-sidebar & {
      gap: 16px;
      padding-right: 16px;
      padding-left: 16px;
    }

    .fc-toolbar.fc-header-toolbar {
      height: unset;
      justify-content: flex-start;

      margin-bottom: 0;
      padding-right: 0;
      padding-left: 0;

      > div {
        &:nth-of-type(2),
        &:last-of-type {
          .p-sidebar & {
            display: none;
          }
        }
      }

      .fc-toolbar-title {
        height: unset;
        width: unset;
        visibility: visible;

        color: var(--text-primary);
        font: 600 14px/20px 'Inter', sans-serif;
      }
    }

    .fc-view-harness-active {
      height: auto!important;
      min-height: auto;

      .fc-view {
        position: static;

        &.fc-list {
          border: none;
        }

        .fc-list-table {
          .fc-list-day {
            display: none;
          }

          .fc-event {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            td {
              background-color: var(--gray-utility-50);
              border: 1px solid var(--gray-utility-200)!important;

              display: block;
              /* flex: 1; */
              gap: 0;
              width: unset;

              border-top: 0;
              padding: 0;

              &.fc-list-event-time {
                order: 1;

                border-left: 0!important;
                border-radius: 0 6px 6px 0;
                padding: 4px 8px;

                color: var(--gray-utility-700);
                font: 400 12px/20px 'Inter', sans-serif;
              }

              &.fc-list-event-title {
                order: 0;
                flex: 1;
                
                border-right: 0!important;
                border-radius: 6px 0 0 6px;
                padding: 4px 8px;

                a {
                  font: 600 12px/18px 'Inter', sans-serif;
                }
              }

              &.fc-list-event-graphic {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
