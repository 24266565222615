.sidebar {
  background-color: var(--gray-0);
  /* box-shadow: unset; */

  justify-content: space-between;
  gap: 16px;

  width: 400px;
  
  :global(.inline)& {
    background-color: var(--gray-50);
    box-shadow: none;

    margin-top: 72px;
    height: calc(100vh - 72px);
    width: 566px;
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  padding: 24px 24px 8px;

  h3 {
    color: var(--text-primary-700);
    font: 700 18px/144% 'Inter', sans-serif;
  }

  .closeIcon {
    margin-left: auto;
    
    > div > svg {
      height: 24px;
      width: 24px;
    }
  }
}

.chevronClose {
  background-color: var(--gray-4);
  border: 1px solid var(--gray-50);
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  height: 52px;
  width: 25px;
  display: none;

  @media screen and (min-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    inset: 25px auto auto -25px;
  }

  &:hover {
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex: 1;

  padding-right: 8px;
  padding-left: 8px;
  overflow-y: scroll;

  :global(.inline) & {
    padding: 24px;
    overflow: hidden;
  }

  > * {
    width: 100%;
  }

  form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    /* justify-content: flex-start; */
    justify-content: space-between;
    /* flex: 1; */
    height: 100%;
    
    padding-right: 16px;
    padding-left: 16px;

    > div {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;

      scrollbar-width: none;
      overflow-y: auto;

      padding-bottom: 24px;
      /* max-height: calc(100vh - 72px - 76px); */
      width: 100%;
      
      &::-webkit-scrollbar {
        display: none;
      }

      > * {
        width: 100%;
      }

      div[data-hw-role='sep'] {
        background-color: var(--border-secondary);
        height: 1px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    footer {
      padding: 16px 0;
      width: 100%;

      display: flex;
      flex-flow: row nowrap;
      /* align-items: center; */
      align-items: flex-end;
      justify-content: flex-end;
      gap: 12px;

      position: relative;

      &::before {
        background-color: var(--border-secondary);
        height: 1px;

        content: '';

        position: absolute;
        inset: 0 -16px;
      }

      button:only-of-type {
        margin-left: auto;
      }
    }
  }
}

.footer {
  padding: 16px 24px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  position: relative;

  &::before {
    background-color: var(--border-secondary);
    height: 1px;

    content: '';

    position: absolute;
    inset: 0 16px;
  }

  button:only-of-type {
    margin-left: auto;
  }
}
