.navigation {
  background-color: var(--gray-4);
  border-bottom: 1px solid var(--border-secondary);
  /* box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.25); */
  display: none;

  position: fixed;
  inset: 72px 0 154px;
  z-index: 100;

  @media screen and (min-width: 768px) {
    background-color: transparent;
    border-bottom: none;

    display: block;
    position: static;
    inset: unset;
    /* position: absolute;
    bottom: auto;
    box-shadow: none; */
    /* width: 100%; */

    /* display: block!important; */
  }

  &.open {
    display: block;
  }

  > ul {
    list-style: none;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;

    padding: 8px;

    width: 100%;

    @media screen and (min-width: 576px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    @media screen and (min-width: 768px) {
      flex-flow: row nowrap;
      align-items: center;
      padding: 0;
    }

    li {
      background-color: transparent;
      border-radius: 6px;
      border-bottom: 3px solid transparent;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 7px;

      color: var(--text-brand-secondary);
      font: 600 16px/24px "Inter", sans-serif;

      cursor: pointer;
      padding: 10px 12px 7px;
      width: 100%;

      transition: all 0.3s ease;

      @media screen and (min-width: 768px) {
        font-size: 12px;
        width: auto;
      }
      @media screen and (min-width: 900px) {
        font-size: 16px;
      }

      &:hover {
        background-color: var(--bg-primary-hover);
        border-bottom: 3px solid var(--bg-primary-hover);
      }

      &.active {
        background-color: var(--bg-active);
        border-bottom: 3px solid var(--brand-accent-500);
        color: var(--gray-0);
      }

      > div {
        @media screen and (min-width: 768px) {
          display: none;
        }

        > svg {
          height: 18px;
          width: 18px;

          path {
            fill: var(--bg-active);
          }
        }
      }
    }
  }
}

.settings {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  .hours {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;

    @media screen and (min-width: 992px) {
      flex-direction: row;
      gap: 4px;
    }

    p {
      color: var(--text-tertiary);
      font: 500 12px/14px "Inter", sans-serif;

      @media screen and (min-width: 992px) {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  button {
    background-color: var(--button-secondary-bg);
    border: 1px solid var(--button-secondary-border);
    box-shadow: 0px -2px 0px 0px rgba(10,13,18,0.05) inset;
    outline: none;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 3px;

    padding: 7px 10px;
    position: relative;

    @media screen and (min-width: 576px) {
      font-size: 14px;
      gap: 10px;
      padding: 10px 14px;
    }

    :global(.p-button-icon) {
      color: var(--button-secondary-fg)!important;
    }
    
    :global(.p-button-label) {
      color: var(--button-secondary-fg)!important;
      font: 600 14px/20px "Inter", sans-serif;
    }

    span[data-pc-section="icon"] {
      font-size: 12px;
      order: 1;
      transition: transform 0.175s ease;
    }

    span[data-pc-section="label"] {
      position: relative;

      &::before {
        content: 'Menu';

        @media screen and (min-width: 768px) {
          content: 'Settings';
        }
      }
    }

    &::before,
    &::after {
      background-color: transparent;
      opacity: 0;

      content: "";
      position: absolute;
      transition: all 0.3s ease;
    }
    &::before {
      border-radius: 8px;
      inset: -2px;
      z-index: -1;
    }
    &::after {
      border-radius: 9px;
      inset: -4px;
      z-index: -2;
    }

    &:hover {
      background-color: var(--button-secondary-bg);
      box-shadow: 0px -2px 0px 0px rgba(10,13,18,0.05) inset, 0px 1px 2px 0px rgba(10,13,18,0.05);

      color: var(--button-secondary-fg);

      &::before {
        background-color: var(--button-secondary-bg);
        opacity: 1;
      }
      &::after {
        background-color: var(--border-secondary);
        opacity: 1;
      }
    }
  }

  &.open {
    button {
      box-shadow: 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05), 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #69A4BF;

      &::before {
        background-color: var(--button-secondary-bg);
        opacity: 1;
      }
      &::after {
        background-color: var(--button-secondary-active);
        opacity: 1;
      }

      span[data-pc-section="icon"] {
        transform: rotate(180deg);
      }
    }
  }

  .backdrop {
    display: none;
    background-color: var(--gray-0);
    position: fixed;
    inset: 0;

    @media screen and (min-width: 768px) {
      background-color: transparent;
      display: block;      
    }
  }
}

.dropdown {
  margin-left: auto;
}
.confirmDialog {
  background-color: var(--gray-0);
  border-radius: 18px;
  padding: 5px;
  gap: 7px;  display: flex;  flex-direction: column;  
  div {
    display: flex;
    column-gap: 10px;
  }
}