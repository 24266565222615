.home {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;

  overflow: hidden;

  @media screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
}

.stalls,
.dashboard {
  overflow-y: scroll;
}

.stalls {
  background-color: var(--bg-secondary);

  position: relative;
  height: calc(100vh - 72px);
  width: 390px;

  > div:first-of-type {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    padding: 24px;
  }
}

.dashboard {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  
  flex: 1;
  padding: 24px 16px;
  height: auto;
  
  @media screen and (min-width: 1400px) {
    flex-flow: row wrap;
    padding: 24px 32px;
  }

  > div {
    flex: 1;
    width: 100%;

    &:first-of-type {
      width: 100%;
      flex: unset;
    }
  }
}
