.button {
  background-color: var(--button-primary-bg);
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05);

  gap: 4px;
  padding: 10px 14px;

  table & {
    padding: 4px 8px;
  }

  &:hover {
    background-color: var(--button-primary-bg-hover);
  }

  &:active {
    background-color: var(--button-primary-bg);
    box-shadow: 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05), 0px 0px 0px 2px var(--gray-0), 0px 0px 0px 4px var(--button-secondary-active);
  }

  :global(.p-button-icon) {
    color: var(--gray-0)!important;
  }

  :global(.p-button-label) {
    color: var(--gray-0);
    font: 600 14px/20px 'Inter', sans-serif;
    text-decoration: none!important;

    table & {
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  &:global(.p-disabled) {
    background-color: var(--bg-disabled);
    border-color: var(--border-disabled-subtle);
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);
    opacity: 1;

    :global(.p-button-label) {
      color: var(--fg-quinary-400);
    }
  }
}

.secondary {
  background-color: var(--gray-0);
  border-color: var(--button-secondary-border);
  border-width: 1px!important;
  box-shadow: 0px -2px 0px 0px rgba(10,13,18,0.05) inset;

  :global(.p-button-icon),
  :global(.p-button-label) {
    color: var(--button-secondary-fg)!important;
  }

  &:hover {
    background-color: var(--bg-secondary);
  }

  &:active {
    background-color: var(--gray-0);
  }
}

.secondaryAlt {
  composes: secondary;
  border-color: var(--text-brand-tertiary);
  
  &:hover {
    background-color: var(--button-secondary-bg-hover);
  }

  &:active {
    background-color: var(--gray-0);
  }
}

.tertiary {
  composes: secondary;
  border-color: transparent;
  box-shadow: unset;
  color: var(--button-tertiary-fg);

  &:hover {
    background-color: var(--button-tertiary-fg-hover);
  }

  :global(.p-button-icon) {
    color: var(--button-tertiary-fg);
  }

  :global(.p-button-label) {
    color: var(--button-tertiary-fg);
  }
}

.destructive {
  composes: button;

  background-color: var(--button-primary-error-bg);
  box-shadow: 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05);

  &:active {
    background-color: var(--button-primary-error-bg);
    box-shadow: 0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05), 0px 0px 0px 2px var(--gray-0), 0px 0px 0px 4px #F04438;
  }

  &:hover {
    background-color: var(--button-primary-error-bg-hover);
  }
}
